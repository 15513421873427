import { ApiErrorResponse } from '@/Model/errors/api/types';
import {
  createPaymentPlan,
  deleteAutomaticDiscount,
  fetchConvenienceFee,
  fetchStorePolicy,
  getAutomaticDiscountsByAccount,
  getPaymentPlanByAccount,
  getStripeOnboardingLink,
  getStripeOnboardingStatus,
  getTaxRates,
  saveConvenienceFee,
  saveStorePolicy,
  updateAutomaticDiscount,
  updatePaymentPlan,
} from '@/services/payments/api';
import store from '@/store';
import APP_UTILITIES from '@/utilities/commonFunctions';
import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule,
} from 'vuex-module-decorators';
import MutationNames from './mutationNames';
import {
  AddUpdateStorePolicyPayload,
  ConvenienceFeeGetResponse,
  ConvenienceFeePutRequest,
  PaymentPlan,
  StorePolicy,
  StripeAccountResponse,
  TaxRate,
} from '@/Model/payments/types';
import { Status } from '@/Model/shared/types';
import { Discount } from '@/Model/payments/types';

const {
  FETCH_ONBOARDING_LINK_REQUESTED,
  FETCH_ONBOARDING_LINK_FAILED,
  FETCH_ONBOARDING_LINK_SUCCEEDED,
  FETCH_ONBOARDING_LINK_ERROR,
  FETCH_ONBOARDING_STATUS_REQUESTED,
  FETCH_ONBOARDING_STATUS_FAILED,
  FETCH_ONBOARDING_STATUS_SUCCEEDED,
  RESET_PAYMENTS_PAGE,
  TAX_RATE_REQUESTED,
  TAX_RATE_SUCCEEDED,
  TAX_RATE_FAILED,
  FETCH_STORE_POLICY_REQUESTED,
  FETCH_STORE_POLICY_SUCCEEDED,
  FETCH_STORE_POLICY_FAILED,
  SAVE_STORE_POLICY_REQUESTED,
  SAVE_STORE_POLICY_SUCCEEDED,
  SAVE_STORE_POLICY_FAILED,
  GET_CUSTOM_FEE_REQUESTED,
  GET_CUSTOM_FEE_SUCCEEDED,
  GET_CUSTOM_FEE_FAILED,
  SAVE_CUSTOM_FEE_FAILED,
  SAVE_CUSTOM_FEE_REQUESTED,
  SAVE_CUSTOM_FEE_SUCCEEDED,
  DELETE_AUTOMATIC_DISCOUNT_REQUESTED,
  DELETE_AUTOMATIC_DISCOUNT_SUCCEEDED,
  DELETE_AUTOMATIC_DISCOUNT_FAILED,
  UPDATE_AUTOMATIC_DISCOUNT_REQUESTED,
  UPDATE_AUTOMATIC_DISCOUNT_SUCCEEDED,
  UPDATE_AUTOMATIC_DISCOUNT_FAILED,
  SET_SELECTED_AUTOMATIC_DISCOUNT,
  FETCH_AUTOMATIC_DISCOUNTS_REQUESTED,
  FETCH_AUTOMATIC_DISCOUNTS_SUCCEEDED,
  FETCH_AUTOMATIC_DISCOUNTS_FAILED,
  UPDATE_AUTOMATIC_DISCOUNT_LIST,
  SET_SELECTED_PAYMENT_PLAN,
  FETCH_PAYMENT_PLANS_REQUESTED,
  FETCH_PAYMENT_PLANS_SUCCEEDED,
  FETCH_PAYMENT_PLANS_FAILED,
  UPDATE_PAYMENT_PLAN_REQUESTED,
  UPDATE_PAYMENT_PLAN_SUCCEEDED,
  UPDATE_PAYMENT_PLAN_FAILED,
  CREATE_PAYMENT_PLAN_REQUESTED,
  CREATE_PAYMENT_PLAN_SUCCEEDED,
  CREATE_PAYMENT_PLAN_FAILED,
} = MutationNames;

@Module({
  namespaced: true,
  name: 'payments',
  store,
  dynamic: true,
})
export class PaymentsModule extends VuexModule {
  didOnboardingLinkFetchFail: boolean = false;
  isFetchingOnboardingLink: boolean = false;
  onboardingLinkFetchError: ApiErrorResponse | null = null;

  didOnboardingStatusFetchFail: boolean = false;
  isFetchingOnboardingStatus: boolean = false;
  onboardingStatusFetchError: ApiErrorResponse | null = null;

  stripeAccountResponse: StripeAccountResponse | null = null;

  isFetchingTaxRates: boolean = false;
  didFailFetchingTaxRates: boolean = false;
  taxRateFailError: ApiErrorResponse | null = null;
  taxRates: TaxRate[] = [];

  isFetchingStorePolicy: boolean = false;
  didFetchStorePolicyFail: boolean = false;
  fetchStorePolicyFailedError: ApiErrorResponse | null = null;
  storePolicy: StorePolicy = {
    enabled: false,
    url: '',
  };

  isSavingStorePolicy: boolean = false;
  didSaveStorePolicyFail: boolean = false;
  saveStorePolicyFailedError: ApiErrorResponse | null = null;

  isLoadingCustomFee: boolean = false;
  isSavingCustomFee: boolean = false;
  didSavingCustomFeeFail: boolean = false;
  customFeeError: ApiErrorResponse | null = null;
  customFee: ConvenienceFeeGetResponse | null = null;

  discountList: Discount[] = [];
  isConfigurationDiscountMenuOpen: boolean = false;
  isBillingMethodMenuOpen: boolean = false;

  isFetchingAutomaticDiscounts: boolean = false;
  didFetchAutomaticDiscountsFail: boolean = false;
  fetchAutomaticDiscountsFailedError: ApiErrorResponse | null = null;

  isDeletingDiscount: boolean = false;
  didDeleteDiscountFail: boolean = false;

  isUpdatingDiscount: boolean = false;
  didUpdateDiscountFail: boolean = false;

  discountError: ApiErrorResponse | null = null;

  selectedDiscount: Discount | null = null;

  paymentPlan: PaymentPlan | null = null;

  isFetchingPaymentPlan: boolean = false;
  didFetchPaymentPlanFail: boolean = false;
  fetchPaymentPlanFailedError: ApiErrorResponse | null = null;

  isUpdatingPaymentPlan: boolean = false;
  didUpdatePaymentPlanFail: boolean = false;
  updatePaymentPlanFailedError: ApiErrorResponse | null = null;
  isCreatingPaymentPlan: boolean = false;
  didCreatePaymentPlanFail: boolean = false;
  createPaymentPlanFailedError: ApiErrorResponse | null = null;
  isLoadingBillingMethods: boolean = false;

  get discountListReference() {
    return this.discountList;
  }

  get getSelectedDiscount() {
    return this.selectedDiscount;
  }

  @Mutation
  [GET_CUSTOM_FEE_REQUESTED]() {
    this.isLoadingCustomFee = true;
    this.customFee = null;
  }

  @Mutation
  [GET_CUSTOM_FEE_SUCCEEDED](fee: ConvenienceFeeGetResponse | null) {
    this.isLoadingCustomFee = false;
    this.customFee = fee;
    this.customFeeError = null;
  }

  @Mutation
  [GET_CUSTOM_FEE_FAILED](error: ApiErrorResponse) {
    this.isLoadingCustomFee = false;
    this.customFeeError = error;
  }

  @Mutation
  [SAVE_CUSTOM_FEE_REQUESTED]() {
    this.isSavingCustomFee = true;
    this.didSavingCustomFeeFail = false;
  }

  @Mutation
  [SAVE_CUSTOM_FEE_SUCCEEDED](fee: ConvenienceFeeGetResponse) {
    this.isSavingCustomFee = false;
    this.customFee = fee;
    this.customFeeError = null;
    this.didSavingCustomFeeFail = false;
  }

  @Mutation
  [SAVE_CUSTOM_FEE_FAILED](error: ApiErrorResponse) {
    this.isSavingCustomFee = false;
    this.customFeeError = error;
    this.didSavingCustomFeeFail = true;
  }

  /**
   * Delete discount start
   */
  @Mutation
  [DELETE_AUTOMATIC_DISCOUNT_REQUESTED]() {
    this.isDeletingDiscount = true;
    this.didDeleteDiscountFail = false;
  }

  @Mutation
  [DELETE_AUTOMATIC_DISCOUNT_SUCCEEDED]() {
    this.isDeletingDiscount = false;
    this.didDeleteDiscountFail = false;
  }

  @Mutation
  [DELETE_AUTOMATIC_DISCOUNT_FAILED](error: ApiErrorResponse) {
    this.isDeletingDiscount = false;
    this.discountError = error;
    this.didDeleteDiscountFail = true;
  }
  /**
   * Delete discount end
   */

  /**
   * Update discount start
   */
  @Mutation
  [UPDATE_AUTOMATIC_DISCOUNT_REQUESTED]() {
    this.isUpdatingDiscount = true;
    this.didUpdateDiscountFail = false;
  }

  @Mutation
  [UPDATE_AUTOMATIC_DISCOUNT_SUCCEEDED]() {
    this.isUpdatingDiscount = false;
    this.didUpdateDiscountFail = false;
  }

  @Mutation
  [UPDATE_AUTOMATIC_DISCOUNT_FAILED](error: ApiErrorResponse) {
    this.isUpdatingDiscount = false;
    this.didUpdateDiscountFail = true;
    this.discountError = error;
  }

  /**
   * Update discount end
   */

  /**
   * fetch discount start
   */

  @Mutation
  [FETCH_AUTOMATIC_DISCOUNTS_REQUESTED]() {
    this.isFetchingAutomaticDiscounts = true;
    this.didFetchAutomaticDiscountsFail = false;
    this.fetchAutomaticDiscountsFailedError = null;
  }

  @Mutation
  [FETCH_AUTOMATIC_DISCOUNTS_SUCCEEDED](discounts: Discount[]) {
    this.isFetchingAutomaticDiscounts = false;
    this.didFetchAutomaticDiscountsFail = false;
    this.fetchAutomaticDiscountsFailedError = null;
    this.discountList = discounts;
  }

  @Mutation
  [FETCH_AUTOMATIC_DISCOUNTS_FAILED](error: ApiErrorResponse) {
    this.isFetchingAutomaticDiscounts = false;
    this.didFetchAutomaticDiscountsFail = true;
    this.fetchAutomaticDiscountsFailedError = error;
  }

  /**
   * fetch discount end
   */

  @Mutation
  [UPDATE_AUTOMATIC_DISCOUNT_LIST](discounts: Discount[]) {
    this.discountList = discounts;
  }

  @Mutation
  [SET_SELECTED_AUTOMATIC_DISCOUNT](discount: Discount | null) {
    this.selectedDiscount = discount;
  }

  @Mutation
  [SET_SELECTED_PAYMENT_PLAN](paymentPlan: PaymentPlan | null) {
    this.paymentPlan = paymentPlan;
  }

  /**
  * Create payment plan start
  */
  @Mutation
  [CREATE_PAYMENT_PLAN_REQUESTED]() {
    this.isCreatingPaymentPlan = true;
    this.didCreatePaymentPlanFail = false;
  }

  @Mutation
  [CREATE_PAYMENT_PLAN_SUCCEEDED]() {
    this.isCreatingPaymentPlan = false;
    this.didCreatePaymentPlanFail = false;
  }

  @Mutation
  [CREATE_PAYMENT_PLAN_FAILED](error: ApiErrorResponse) {
    this.isCreatingPaymentPlan = false;
    this.didCreatePaymentPlanFail = true;
    this.createPaymentPlanFailedError = error;
  }

  /**
  * Create payment plan end
  */
    /**
    * Update payment plan start
    */
    @Mutation
  [UPDATE_PAYMENT_PLAN_REQUESTED]() {
    this.isUpdatingPaymentPlan = true;
    this.didUpdatePaymentPlanFail = false;
  }

    @Mutation
    [UPDATE_PAYMENT_PLAN_SUCCEEDED]() {
      this.isUpdatingPaymentPlan = false;
      this.didUpdatePaymentPlanFail = false;
    }

    @Mutation
    [UPDATE_PAYMENT_PLAN_FAILED](error: ApiErrorResponse) {
      this.isUpdatingPaymentPlan = false;
      this.didUpdatePaymentPlanFail = true;
      this.updatePaymentPlanFailedError = error;
    }

    /**
    * Update payment plan end
    */

    /**
    * Fetch payment plans start
    */

    @Mutation
    [FETCH_PAYMENT_PLANS_REQUESTED]() {
      this.isFetchingPaymentPlan = true;
      this.didFetchPaymentPlanFail = false;
      this.fetchPaymentPlanFailedError = null;
    }

    @Mutation
    [FETCH_PAYMENT_PLANS_SUCCEEDED](plan: PaymentPlan) {
      this.isFetchingPaymentPlan = false;
      this.didFetchPaymentPlanFail = false;
      this.fetchPaymentPlanFailedError = null;
      this.paymentPlan = plan;
    }

    @Mutation
    [FETCH_PAYMENT_PLANS_FAILED](error: ApiErrorResponse) {
      this.isFetchingPaymentPlan = false;
      this.didFetchPaymentPlanFail = true;
      this.fetchPaymentPlanFailedError = error;
    }

    /**
    * Fetch payment plans end
    */

  @Mutation
    [FETCH_ONBOARDING_LINK_REQUESTED]() {
      this.didOnboardingLinkFetchFail = false;
      this.isFetchingOnboardingLink = true;
      this.onboardingLinkFetchError = null;
    }

  @Mutation
  [FETCH_ONBOARDING_LINK_FAILED]() {
    this.didOnboardingLinkFetchFail = true;
    this.isFetchingOnboardingLink = false;
    this.onboardingLinkFetchError = null;
  }

  @Mutation
  [FETCH_ONBOARDING_LINK_SUCCEEDED]() {
    this.didOnboardingLinkFetchFail = false;
    this.isFetchingOnboardingLink = false;
    this.onboardingLinkFetchError = null;
  }

  @Mutation
  [FETCH_ONBOARDING_LINK_ERROR](error: any) {
    this.didOnboardingLinkFetchFail = true;
    this.isFetchingOnboardingLink = false;
    this.onboardingLinkFetchError =
      APP_UTILITIES.errorToApiErrorResponse(error);
  }

  @Mutation
  [FETCH_ONBOARDING_STATUS_REQUESTED]() {
    this.stripeAccountResponse = null;
    this.didOnboardingStatusFetchFail = false;
    this.isFetchingOnboardingStatus = true;
    this.onboardingStatusFetchError = null;
  }

  @Mutation
  [FETCH_ONBOARDING_STATUS_FAILED](error: any) {
    this.stripeAccountResponse = null;
    this.didOnboardingStatusFetchFail = true;
    this.isFetchingOnboardingStatus = false;
    this.onboardingStatusFetchError =
      APP_UTILITIES.errorToApiErrorResponse(error);
  }

  @Mutation
  [FETCH_ONBOARDING_STATUS_SUCCEEDED](response: StripeAccountResponse) {
    this.stripeAccountResponse = response;
    this.didOnboardingStatusFetchFail = false;
    this.isFetchingOnboardingStatus = false;
    this.onboardingStatusFetchError = null;
  }

  @Mutation
  [RESET_PAYMENTS_PAGE]() {
    this.didOnboardingLinkFetchFail = false;
    this.isFetchingOnboardingLink = false;
    this.onboardingLinkFetchError = null;

    this.didOnboardingStatusFetchFail = false;
    this.isFetchingOnboardingStatus = false;
    this.onboardingStatusFetchError = null;

    this.stripeAccountResponse = null;

    this.isLoadingCustomFee = false;
    this.isSavingCustomFee = false;
    this.customFee = null;
    this.customFeeError = null;
  }

  @Mutation
  [TAX_RATE_REQUESTED]() {
    this.isFetchingTaxRates = true;
    this.didFailFetchingTaxRates = false;
    this.taxRateFailError = null;
  }

  @Mutation
  [TAX_RATE_SUCCEEDED](taxRates: TaxRate[]) {
    this.taxRates = taxRates;
    this.isFetchingTaxRates = false;
    this.didFailFetchingTaxRates = false;
    this.taxRateFailError = null;
  }

  @Mutation
  [TAX_RATE_FAILED](error: ApiErrorResponse) {
    this.taxRates = [];
    this.isFetchingTaxRates = false;
    this.didFailFetchingTaxRates = true;
    this.taxRateFailError = error;
  }

  @Mutation
  [FETCH_STORE_POLICY_REQUESTED]() {
    this.isFetchingStorePolicy = true;
    this.didFetchStorePolicyFail = false;
    this.fetchStorePolicyFailedError = null;
  }

  @Mutation
  [FETCH_STORE_POLICY_SUCCEEDED](storePolicy: StorePolicy) {
    this.isFetchingStorePolicy = false;
    this.didFetchStorePolicyFail = false;
    this.fetchStorePolicyFailedError = null;
    this.storePolicy = storePolicy;
  }

  @Mutation
  [FETCH_STORE_POLICY_FAILED](error: ApiErrorResponse) {
    this.isFetchingStorePolicy = false;
    this.didFetchStorePolicyFail = true;
    this.fetchStorePolicyFailedError = error;
  }

  @Mutation
  [SAVE_STORE_POLICY_REQUESTED]() {
    this.isSavingStorePolicy = true;
    this.didSaveStorePolicyFail = false;
    this.saveStorePolicyFailedError = null;
  }

  @Mutation
  [SAVE_STORE_POLICY_SUCCEEDED](storePolicy: StorePolicy) {
    this.isSavingStorePolicy = false;
    this.didSaveStorePolicyFail = false;
    this.saveStorePolicyFailedError = null;
    this.storePolicy = storePolicy;
  }

  @Mutation
  [SAVE_STORE_POLICY_FAILED](error: ApiErrorResponse) {
    this.isSavingStorePolicy = false;
    this.didSaveStorePolicyFail = true;
    this.saveStorePolicyFailedError = error;
  }

  @Action
  changeCustomFeeStatus() {
    if (this.customFee) {
      this.customFee.status == Status.Active
        ? (this.customFee.status = Status.Inactive)
        : (this.customFee.status = Status.Active);
    }
  }

  @Action
  async getOnboardingLink(accountId: number): Promise<string | undefined> {
    try {
      this.FETCH_ONBOARDING_LINK_REQUESTED();
      const response = await getStripeOnboardingLink(accountId);
      if (response && response.status == 200) {
        this.FETCH_ONBOARDING_LINK_SUCCEEDED();
        return response.data;
      }
      else {
        this.FETCH_ONBOARDING_LINK_FAILED();
        return;
      }
    }
    catch (error) {
      this.FETCH_ONBOARDING_LINK_ERROR(error);
      return;
    }
  }

  @Action
  async getOnboardingStatus(accountId: number) {
    try {
      this.FETCH_ONBOARDING_STATUS_REQUESTED();

      const response = await getStripeOnboardingStatus(accountId);

      this.FETCH_ONBOARDING_STATUS_SUCCEEDED(response.data);
    }
    catch (error) {
      this.FETCH_ONBOARDING_STATUS_FAILED(error);
    }
  }

  @Action
  resetOnboardingState() {
    this.RESET_PAYMENTS_PAGE();
  }

  @Action
  async getTaxRates(accountId: number) {
    try {
      this.TAX_RATE_REQUESTED();

      const response = (await getTaxRates(accountId)).data;
      this.TAX_RATE_SUCCEEDED(response.taxRates);
    }
    catch (error) {
      const apiErrorResponse: ApiErrorResponse =
        APP_UTILITIES.errorToApiErrorResponse(error);
      this.TAX_RATE_FAILED(apiErrorResponse);
    }
  }

  @Action
  async fetchStorePolicy(accountId: number) {
    try {
      this.FETCH_STORE_POLICY_REQUESTED();
      const response = await fetchStorePolicy(accountId);
      this.FETCH_STORE_POLICY_SUCCEEDED(response.data);
    }
    catch (error) {
      const apiErrorResponse: ApiErrorResponse =
        APP_UTILITIES.errorToApiErrorResponse(error);
      this.FETCH_STORE_POLICY_FAILED(apiErrorResponse);
    }
  }

  @Action
  async saveStorePolicy(payload: AddUpdateStorePolicyPayload) {
    try {
      this.SAVE_STORE_POLICY_REQUESTED();
      await saveStorePolicy(payload);
      this.SAVE_STORE_POLICY_SUCCEEDED(payload);
    }
    catch (error) {
      const apiErrorResponse: ApiErrorResponse =
        APP_UTILITIES.errorToApiErrorResponse(error);
      this.SAVE_STORE_POLICY_FAILED(apiErrorResponse);
    }
  }

  @Action
  async retrieveCustomFee() {
    try {
      this.GET_CUSTOM_FEE_REQUESTED();

      const accountId = Number(APP_UTILITIES.getCookie('accountId')) || 0;

      const response = await fetchConvenienceFee(accountId);

      this.GET_CUSTOM_FEE_SUCCEEDED(response.data);
    }
    catch (error) {
      const apiErrorResponse: ApiErrorResponse =
        APP_UTILITIES.errorToApiErrorResponse(error);
      this.GET_CUSTOM_FEE_FAILED(apiErrorResponse);
    }
  }

  @Action
  async updateCustomFee(fee: ConvenienceFeeGetResponse) {
    try {
      const accountId = Number(APP_UTILITIES.getCookie('accountId')) || 0;

      const updatedFee = {
        ...fee,
        accountId: accountId,
      } as ConvenienceFeePutRequest;

      if (updatedFee.id == 0) {
        updatedFee.id = undefined;
      }

      this.SAVE_CUSTOM_FEE_REQUESTED();

      const response = await saveConvenienceFee(updatedFee);

      this.SAVE_CUSTOM_FEE_SUCCEEDED(response.data);
    }
    catch (error) {
      const apiErrorResponse: ApiErrorResponse =
        APP_UTILITIES.errorToApiErrorResponse(error);
      this.SAVE_CUSTOM_FEE_FAILED(apiErrorResponse);
    }
  }

  @Action
  async fetchAutomaticDiscountsByAccount(): Promise<void> {
    try {
      this.FETCH_AUTOMATIC_DISCOUNTS_REQUESTED();

      const accountId = Number(APP_UTILITIES.getCookie('accountId')) || 0;

      const response = await getAutomaticDiscountsByAccount(accountId);

      this.FETCH_AUTOMATIC_DISCOUNTS_SUCCEEDED(response.data);
    }
    catch (error) {
      const apiErrorResponse: ApiErrorResponse =
        APP_UTILITIES.errorToApiErrorResponse(error);
      this.FETCH_AUTOMATIC_DISCOUNTS_FAILED(apiErrorResponse);
    }
  }

  @Action
  async deleteAutomaticDiscount(discountId: number): Promise<void> {
    try {
      this.DELETE_AUTOMATIC_DISCOUNT_REQUESTED();

      await deleteAutomaticDiscount(discountId);

      await this.fetchAutomaticDiscountsByAccount();

      this.DELETE_AUTOMATIC_DISCOUNT_SUCCEEDED();
    }
    catch (error) {
      const apiErrorResponse: ApiErrorResponse =
        APP_UTILITIES.errorToApiErrorResponse(error);
      this.DELETE_AUTOMATIC_DISCOUNT_FAILED(apiErrorResponse);
    }
  }

  @Action
  async updateAutomaticDiscount(payload: Discount): Promise<void> {
    try {
      this.UPDATE_AUTOMATIC_DISCOUNT_REQUESTED();

      await updateAutomaticDiscount(payload);
      this.setIsConfiguratioDiscountMenuOpen(false);
      await this.fetchAutomaticDiscountsByAccount();

      this.UPDATE_AUTOMATIC_DISCOUNT_SUCCEEDED();
    }
    catch (error) {
      const apiErrorResponse: ApiErrorResponse =
        APP_UTILITIES.errorToApiErrorResponse(error);
      this.UPDATE_AUTOMATIC_DISCOUNT_FAILED(apiErrorResponse);
    }
  }

  @Mutation
  setIsConfiguratioDiscountMenuOpen(isOpen: boolean): void {
    this.isConfigurationDiscountMenuOpen = isOpen;
  }

  @Mutation
  /**
   * Sets the state of the billing method menu.
   * @param {boolean} isMenuOpen - The state of the menu (true for open, false for closed).
   * @returns {void}
   */
  setIsBillingMethodMenuOpen(isMenuOpen: boolean): void {
    this.isBillingMethodMenuOpen = isMenuOpen;
  }

  @Action
  async fetchPaymentPlan(): Promise<void> {
    try {
      this[FETCH_PAYMENT_PLANS_REQUESTED]();

      const accountId = Number(APP_UTILITIES.getCookie('accountId')) || 0;

      const response = await getPaymentPlanByAccount(accountId);

      this[FETCH_PAYMENT_PLANS_SUCCEEDED](response.data);
    }
    catch (error) {
      const apiErrorResponse: ApiErrorResponse =
        APP_UTILITIES.errorToApiErrorResponse(error);
      this[FETCH_PAYMENT_PLANS_FAILED](apiErrorResponse);
    }
  }

  @Action
  async createPaymentPlan(payload: Omit<PaymentPlan, 'id' | 'isActive'>): Promise<void> {
    try {
      this[CREATE_PAYMENT_PLAN_REQUESTED]();

      await createPaymentPlan(payload);
      this.setIsBillingMethodMenuOpen(false);
      await this.fetchPaymentPlan();

      this[CREATE_PAYMENT_PLAN_SUCCEEDED]();
    }
    catch (error) {
      const apiErrorResponse: ApiErrorResponse =
        APP_UTILITIES.errorToApiErrorResponse(error);
      this[CREATE_PAYMENT_PLAN_FAILED](apiErrorResponse);
    }
  }

  @Action
  async updatePaymentPlan(payload: PaymentPlan): Promise<void> {
    try {
      this[UPDATE_PAYMENT_PLAN_REQUESTED]();

      await updatePaymentPlan(payload);
      this.setIsBillingMethodMenuOpen(false);
      await this.fetchPaymentPlan();

      this[UPDATE_PAYMENT_PLAN_SUCCEEDED]();
    }
    catch (error) {
      const apiErrorResponse: ApiErrorResponse =
        APP_UTILITIES.errorToApiErrorResponse(error);
      this[UPDATE_PAYMENT_PLAN_FAILED](apiErrorResponse);
    }
  }
}

export default getModule(PaymentsModule);
