enum MutationNames {
  FETCH_ONBOARDING_LINK_REQUESTED = 'FETCH_ONBOARDING_LINK_REQUESTED',
  FETCH_ONBOARDING_LINK_FAILED = 'FETCH_ONBOARDING_LINK_FAILED',
  FETCH_ONBOARDING_LINK_SUCCEEDED = 'FETCH_ONBOARDING_LINK_SUCCEEDED',
  FETCH_ONBOARDING_LINK_ERROR = 'FETCH_ONBOARDING_LINK_ERROR',
  FETCH_ONBOARDING_STATUS_REQUESTED = 'FETCH_ONBOARDING_STATUS_REQUESTED',
  FETCH_ONBOARDING_STATUS_FAILED = 'FETCH_ONBOARDING_STATUS_FAILED',
  FETCH_ONBOARDING_STATUS_SUCCEEDED = 'FETCH_ONBOARDING_STATUS_SUCCEEDED',
  RESET_PAYMENTS_PAGE = 'RESET_PAYMENTS_PAGE',
  TAX_RATE_REQUESTED = 'TAX_RATE_REQUESTED',
  TAX_RATE_SUCCEEDED = 'TAX_RATE_SUCCEEDED',
  TAX_RATE_FAILED = 'TAX_RATE_FAILED',
  FETCH_STORE_POLICY_REQUESTED = 'FETCH_STORE_POLICY_REQUESTED',
  FETCH_STORE_POLICY_SUCCEEDED = 'FETCH_STORE_POLICY_SUCCEEDED',
  FETCH_STORE_POLICY_FAILED = 'FETCH_STORE_POLICY_FAILED',
  SAVE_STORE_POLICY_REQUESTED = 'SAVE_STORE_POLICY_REQUESTED',
  SAVE_STORE_POLICY_SUCCEEDED = 'SAVE_STORE_POLICY_SUCCEEDED',
  SAVE_STORE_POLICY_FAILED = 'SAVE_STORE_POLICY_FAILED',
  GET_CUSTOM_FEE_REQUESTED = 'GET_CUSTOM_FEE_REQUESTED',
  GET_CUSTOM_FEE_SUCCEEDED = 'GET_CUSTOM_FEE_SUCCEEDED',
  GET_CUSTOM_FEE_FAILED = 'GET_CUSTOM_FEE_FAILED',
  SAVE_CUSTOM_FEE_REQUESTED = 'SAVE_CUSTOM_FEE_REQUESTED',
  SAVE_CUSTOM_FEE_SUCCEEDED = 'SAVE_CUSTOM_FEE_SUCCEEDED',
  SAVE_CUSTOM_FEE_FAILED = 'SAVE_CUSTOM_FEE_FAILED',
  DELETE_AUTOMATIC_DISCOUNT_SUCCEEDED = 'DELETE_AUTOMATIC_DISCOUNT_SUCCEEDED',
  DELETE_AUTOMATIC_DISCOUNT_REQUESTED = 'DELETE_AUTOMATIC_DISCOUNT_REQUESTED',
  DELETE_AUTOMATIC_DISCOUNT_FAILED = 'DELETE_AUTOMATIC_DISCOUNT_FAILED',
  UPDATE_AUTOMATIC_DISCOUNT_REQUESTED = 'UPDATE_AUTOMATIC_DISCOUNT_REQUESTED',
  UPDATE_AUTOMATIC_DISCOUNT_SUCCEEDED = 'UPDATE_AUTOMATIC_DISCOUNT_SUCCEEDED',
  UPDATE_AUTOMATIC_DISCOUNT_FAILED = 'UPDATE_AUTOMATIC_DISCOUNT_FAILED',
  SET_SELECTED_AUTOMATIC_DISCOUNT = 'SET_SELECTED_AUTOMATIC_DISCOUNT',
  FETCH_AUTOMATIC_DISCOUNTS_REQUESTED = 'FETCH_AUTOMATIC_DISCOUNTS_REQUESTED',
  FETCH_AUTOMATIC_DISCOUNTS_SUCCEEDED = 'FETCH_AUTOMATIC_DISCOUNTS_SUCCEEDED',
  FETCH_AUTOMATIC_DISCOUNTS_FAILED = 'FETCH_AUTOMATIC_DISCOUNTS_FAILED',
  UPDATE_AUTOMATIC_DISCOUNT_LIST = 'UPDATE_AUTOMATIC_DISCOUNT_LIST',
  SET_SELECTED_PAYMENT_PLAN = 'SET_SELECTED_PAYMENT_PLAN',
  FETCH_PAYMENT_PLANS_REQUESTED = 'FETCH_PAYMENT_PLANS_REQUESTED',
  FETCH_PAYMENT_PLANS_SUCCEEDED = 'FETCH_PAYMENT_PLANS_SUCCEEDED',
  FETCH_PAYMENT_PLANS_FAILED = 'FETCH_PAYMENT_PLANS_FAILED',
  UPDATE_PAYMENT_PLAN_REQUESTED = 'UPDATE_PAYMENT_PLAN_REQUESTED',
  UPDATE_PAYMENT_PLAN_SUCCEEDED = 'UPDATE_PAYMENT_PLAN_SUCCEEDED',
  UPDATE_PAYMENT_PLAN_FAILED = 'UPDATE_PAYMENT_PLAN_FAILED',
  CREATE_PAYMENT_PLAN_REQUESTED = 'CREATE_PAYMENT_PLAN_REQUESTED',
  CREATE_PAYMENT_PLAN_SUCCEEDED = 'CREATE_PAYMENT_PLAN_SUCCEEDED',
  CREATE_PAYMENT_PLAN_FAILED = 'CREATE_PAYMENT_PLAN_FAILED',
}

export default MutationNames;
